var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-bar is-flex is-align-items-center has-text-white"},[_c('popper',{attrs:{"options":{
		placement: 'top',
		modifiers: { offset: { offset: '0,4px' } }
	},"enter-active-class":"fade-enter-active","leave-active-class":"","transition":"fade","trigger":"hover"}},[_c('div',{staticClass:"popper tooltip-content"},[_vm._v(" "+_vm._s(_vm.$t('Submit a feedback or report an issue'))+" ")]),_c('a',{attrs:{"slot":"reference"},on:{"click":_vm.showFeedback},slot:"reference"},[_c('b-icon',{attrs:{"icon":"eedback","pack":"casa"}})],1)]),_c('popper',{attrs:{"options":{
		placement: 'top',
		modifiers: { offset: { offset: '0,4px' } }
	},"enter-active-class":"fade-enter-active","leave-active-class":"","transition":"fade","trigger":"hover"}},[_c('div',{staticClass:"popper tooltip-content"},[_vm._v(" "+_vm._s(_vm.$t('Join Discord'))+" ")]),_c('a',{attrs:{"slot":"reference","rel":"noopener","href":"https://discord.gg/knqAbbBbeX","target":"_blank"},on:{"click":function($event){return _vm.$messageBus('connect_discord')}},slot:"reference"},[_c('b-icon',{attrs:{"icon":"discord","pack":"casa"}})],1)]),_c('popper',{attrs:{"options":{
		placement: 'top',
		modifiers: { offset: { offset: '0,4px' } }
	},"enter-active-class":"fade-enter-active","leave-active-class":"","transition":"fade","trigger":"hover"}},[_c('div',{staticClass:"popper tooltip-content"},[_vm._v(" "+_vm._s(_vm.$t('Visit our Github'))+" ")]),_c('a',{attrs:{"slot":"reference","rel":"noopener","href":"https://github.com/IceWhaleTech/CasaOS","target":"_blank"},on:{"click":function($event){return _vm.$messageBus('connect_github')}},slot:"reference"},[_c('b-icon',{attrs:{"icon":"github","pack":"casa"}})],1)]),_c('popper',{attrs:{"options":{
		placement: 'top',
		modifiers: { offset: { offset: '0,4px' } }
	},"enter-active-class":"fade-enter-active","leave-active-class":"","transition":"fade","trigger":"hover"}},[_c('div',{staticClass:"popper tooltip-content"},[_vm._v(" "+_vm._s(_vm.$t('Share CasaOS'))+" ")]),_c('a',{attrs:{"slot":"reference"},on:{"click":_vm.showShareModal},slot:"reference"},[_c('b-icon',{attrs:{"icon":"chat","pack":"casa"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }